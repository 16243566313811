@import "../abstracts/_variables.scss";
@import "../abstracts/_mixins.scss";

.condition__catalogue__filters {
  background-color: $gray;
  @include breakpoint($xl) {
    width: 22%;
  }
  .filters {
    &__container {
      padding: 2rem 1rem;
      // padding-left: 2rem;
    }

    &__header {
      display: flex;
      align-items: center;
      margin-bottom: 1rem;
    }

    &__title {
      font-family: $main-font;
      font-size: 1.75rem;
      font-weight: 500;
    }

    &__icon {
      margin-right: 10px;
    }

    &__toggle {
      display: block;
      text-align: center;
      user-select: none;
      position: relative;

      &__checkbox,
      &__btn {
        &::before,
        &::after {
          content: "";
          display: block;
          @include baseTransition(0.3s);
        }
      }

      &__checkbox {
        display: none;

        &:checked {
          & + .filters__toggle__btn {
            background-color: $red;

            &::before {
              transform: translate(20px, 0);
            }
          }
        }
      }

      &__btn {
        display: block;
        margin: 0 auto;
        font-size: 1.4rem;
        @include baseTransition(0.3s);
        cursor: pointer;
        position: relative;
        height: 25px;
        width: 45px;
        border-radius: 20px;
        background-color: #ccc;

        &::before {
          position: absolute;
          top: 0;
          left: 0;
          width: 25px;
          height: 25px;
          border-radius: 50%;
          background-color: #000;
        }
      }
    }

    &__filter {
      padding: 7.5px 0;
      width: auto;
      cursor: pointer;

      &-container {
        display: flex;
        flex-wrap: wrap;
        align-items: stretch;
        justify-content: space-evenly;
        padding: 0;
        margin-left: -7.5px;
        margin-right: -7.5px;
        min-width: calc(100% + 15px);
        display: block;
        @include baseTransition(0.3s);
      }

      &__content {
        @include baseTransition(0.2s);
        padding: 0.5rem 0;
        height: 100%;
      }

      &--clear,
      &--toggle {
        .filters__filter__content {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
      }

      &__name {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-family: $main-font;
        font-size: 1.3rem;
        font-weight: 500;
        width: 100%;
        padding: 10px;

        &--small {
          font-size: 1rem;
          &:hover {
            background-color: #ffffff;
          }
        }
      }

      &--dropdown {
        .filters__filter__name::after {
          content: "\f067";
          font-size: 1.4rem;
          font-family: "Font Awesome 5 Pro";
          font-weight: 300;
        }

        &.open {
          .filters__filter__name::after {
            content: "\f068";
          }
        }
      }

      &__dropdown-menu {
        @include baseTransition(0.3s);
        padding: 0;

        &:not(.open) {
          height: 0;
          overflow: hidden;
          display: none;
        }

        &__title {
          font-family: $main-font;
          font-weight: 500;
          font-size: 0.9rem;
          color: #666;
          margin-bottom: 0.5rem;
        }

        &__item {
          width: 100%;

          &-container {
            list-style: none;
          }

          &__name {
            font-size: 1.1rem;
            font-weight: 500;
            padding: 1rem 10px;
            @include baseTransition(0.2s);
            display: flex;
            justify-content: space-between;
            align-items: center;

            &::after {
              content: "";
              width: 17.5px;
              height: 17.5px;
              border-radius: 50%;
              border: 1.5px solid #000;
            }
            &:hover,
            &.active {
              background-color: $red;
              color: #fff;

              &::after {
                border-color: #fff;
                background-color: #fff;
                box-shadow: inset 0 0 0 2px $red;
              }
              .filters__filter__dropdown-menu__item__name {
              }
            }
          }
        }
      }
    }
  }

  @include breakpoint($md) {
    background-color: $gray;
    .filters {
      &__container {
        padding: 1.5rem 1rem 0.5rem;
      }

      &__filter {
        &-container {
          &:not(.show) {
            display: none;
            position: absolute;
            z-index: -1;
          }
        }
      }
    }
  }
}

.products__product {
  margin-bottom: 1rem;
}
