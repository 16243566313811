.text {
    &-white {
        color: #fff;
    }

    &-black {
        color: #000;
    }

    &-red {
        color: $red;
    }

    &-semi-bold {
        font-weight: 600;
    }

    &-bold {
        font-weight: 700;
    }

    &-uppercase {
        text-transform: uppercase;
    }

    &-capitalize {
        text-transform: capitalize;
    }

    &-center {
        text-align: center;
    }

    &-right {
        text-align: right;
    }

    &-striked {
        text-decoration: line-through;
    }
}

.quintech {
    color: $qt;
    font-family: $third-font;
}