.catalogue {
  background-color: $gray;
  // padding-top: 4vh;
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;

  @include breakpoint($md) {
    padding-top: 0;
    background-color: #fff;
  }

  .jumbotron {
    width: 100%;
    height: 400px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    @include breakpoint($md) {
      height: 250px;
      padding-top: 0;
      background-color: #fff;
    }
  }

  .filters {
    &__container {
      padding: 2rem 1rem;
      padding-left: 2rem;
    }

    &__header {
      display: flex;
      align-items: center;
      margin-bottom: 1rem;
    }

    &__title {
      font-family: $main-font;
      font-size: 1.75rem;
      font-weight: 500;
    }

    &__icon {
      margin-right: 10px;
    }

    &__toggle {
      display: block;
      text-align: center;
      user-select: none;
      position: relative;

      &__checkbox,
      &__btn {
        &::before,
        &::after {
          content: "";
          display: block;
          @include baseTransition(0.3s);
        }
      }

      &__checkbox {
        display: none;

        &:checked {
          & + .filters__toggle__btn {
            background-color: $red;

            &::before {
              transform: translate(20px, 0);
            }
          }
        }
      }

      &__btn {
        display: block;
        margin: 0 auto;
        font-size: 1.4rem;
        @include baseTransition(0.3s);
        cursor: pointer;
        position: relative;
        height: 25px;
        width: 45px;
        border-radius: 20px;
        background-color: #ccc;

        &::before {
          position: absolute;
          top: 0;
          left: 0;
          width: 25px;
          height: 25px;
          border-radius: 50%;
          background-color: #000;
        }
      }
    }

    &__filter {
      padding: 7.5px 0;
      width: auto;
      cursor: pointer;

      &-container {
        display: flex;
        flex-wrap: wrap;
        align-items: stretch;
        justify-content: space-evenly;
        padding: 0;
        margin-left: -7.5px;
        margin-right: -7.5px;
        min-width: calc(100% + 15px);
        display: block;
        @include baseTransition(0.3s);
      }

      &__content {
        @include baseTransition(0.2s);
        padding: 0.5rem 0;
        height: 100%;
      }

      &--clear,
      &--toggle {
        .filters__filter__content {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
      }

      &__name {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-family: $main-font;
        font-size: 1.3rem;
        font-weight: 500;
        width: 100%;
        padding: 10px;

        &--small {
          font-size: 1rem;
          &:hover {
            background-color: #ffffff;
          }
        }
      }

      &--dropdown {
        .filters__filter__name::after {
          content: "\f067";
          font-size: 1.4rem;
          font-family: "Font Awesome 5 Pro";
          font-weight: 300;
        }

        &.open {
          .filters__filter__name::after {
            content: "\f068";
          }
        }
      }

      &__dropdown-menu {
        @include baseTransition(0.3s);
        padding: 0;

        &:not(.open) {
          height: 0;
          overflow: hidden;
          display: none;
        }

        &__title {
          font-family: $main-font;
          font-weight: 500;
          font-size: 0.9rem;
          color: #666;
          margin-bottom: 0.5rem;
        }

        &__item {
          width: 100%;

          &-container {
            list-style: none;
          }

          &__name {
            font-size: 1.1rem;
            font-weight: 500;
            padding: 1rem 10px;
            @include baseTransition(0.2s);
            display: flex;
            justify-content: space-between;
            align-items: center;
            text-transform: capitalize;
            &::after {
              content: "";
              width: 17.5px;
              height: 17.5px;
              border-radius: 50%;
              border: 1.5px solid #000;
            }
            span {
              width: 75%;
              @include breakpoint($md) {
                width: 100%;
              }
            }
            &:hover,
            &.active {
              background-color: $red;
              color: #fff;
              &::after {
                border-color: #fff;
                background-color: #fff;
                box-shadow: inset 0 0 0 2px $red;
              }
              // .filters__filter__dropdown-menu__item__name {
              // }
            }
          }
        }
      }
    }

    @include breakpoint($md) {
      background-color: $gray;

      &__container {
        padding: 1.5rem 1rem 0.5rem;
      }

      &__filter {
        &-container {
          &:not(.show) {
            display: none;
            position: absolute;
            z-index: -1;
          }
        }
      }
    }
  }

  .products {
    &__header {
      background-color: $gray;
      padding: 2rem;
    }

    &__title {
      font-size: clamp(1.5rem, 2.25vw, 2.75rem);
      font-weight: 500;
      margin-bottom: 1rem;
    }

    &__text {
      font-size: clamp(0.9rem, 1.125vw, 1.35rem);
      line-height: clamp(1.25rem, 1.65vw, 1.95rem);
    }

    &__product {
      position: relative;
      // padding: 0;
      overflow: hidden;
      margin-top: 1rem;
      // padding: 0 1rem;
      // &:nth-child(2n) {
      //   padding-left: 1rem;
      // }
      &-container {
        display: flex;
        flex-wrap: wrap;
        background-color: #fff;
        padding: 0 1rem;
        min-height: 70vh;
        @include breakpoint($md) {
          padding: 2rem 1rem;
        }
        .row {
          width: 100%;
        }
      }

      &-holder {
        background-color: #f8f8f8;
      }

      &__image {
        position: absolute;
        top: 45%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: block;
        width: 95%;
        object-fit: cover;
        margin-bottom: 0;
        height: 350px;
        @include breakpoint($md) {
          top: 50%;
          width: 100%;
        }
        &-container {
          width: 100%;
          height: 400px;
          position: relative;
          overflow: hidden;
          @include breakpoint($md) {
            // width: 450px;
          }
          a {
            display: block;
            width: 100%;
          }
        }
      }

      &__content {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        padding: 0 1rem 1rem 1rem;
        flex-direction: column;
        p {
          span {
            color: #666;
            font-size: 0.8rem;
            &:nth-child(2n) {
              color: #000;
              font-size: 0.9rem;
            }
          }
        }
      }

      &__name {
        text-transform: uppercase;
        font-family: $main-font;
        font-weight: 500;
        font-size: clamp(0.75rem, 0.9vw, 1.1rem);
      }

      &:hover {
        .products__product__overlay {
          opacity: 1;
          z-index: 0;
          background-color: #f8f8f8;
          height: 100%;
        }
      }

      &__overlay {
        position: absolute;
        top: 0;
        left: 15px;
        // width: calc(100% - 30px);
        // height: calc(100% - 30px);
        @include baseTransition(0.3s);
        opacity: 0;
        z-index: -1;

        &__image {
          // width: auto;
          // height: 100%;
          // object-fit: cover;

          &-container {
            width: 100%;
            height: 100%;
          }
        }

        &__content {
          position: absolute;
          bottom: 0;
          width: 100%;
          padding: 1rem;
        }

        &__cta {
          background-color: #000;
          padding: 1rem;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;

          &__text {
            font-size: 1.1rem;
            color: #fff;
          }

          &:hover {
            background-color: $red;
          }
        }
      }
    }

    @include breakpoint($md) {
      &__header {
        text-align: center;
        padding: 5vh 1vh 2.5vh;
        background-color: #fff;
      }

      &__product {
        height: 410px;
        margin-top: 1.2rem;
        &-holder {
          height: 100%;
        }
        &-container {
          padding: 2.5vh 0;
          margin-right: 1rem;
          margin-left: 1rem;
          justify-content: space-between;
          .row {
            width: 100vw;
          }
        }

        &__image {
          &-container {
            height: 290px;
          }
          width: auto;
          height: 100%;
        }

        &__content {
          padding: 1.3rem 5px 0.5rem 5px;
        }

        &__name {
          line-height: 0.9rem;
        }
        &__overlay {
          // height: 390px;
          top: 0;
          // width: auto;
          left: 0;
          &__image-container {
            width: unset;
            height: 80%;
          }
        }
      }
    }
  }
}
