.whatsapp-cta {
    border-radius: 50%;
    background-color: $red;
    padding: 1rem;
    cursor: pointer;
    @include baseTransition(.2s);
    -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.3);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.3);

    &__container {
        position: fixed;
        bottom: 2rem;
        right: 2rem;
        z-index: 10;
    }

    &__icon {
        color: #fff;
        font-size: 2rem;
    }

    &:hover {
        -webkit-box-shadow: 0 0 20px 5px rgba(0, 0, 0, 0.2);
        box-shadow: 0 0 20px 5px rgba(0, 0, 0, 0.2);
    }

    @include breakpoint($md) {
        &__container {
            right: 1.5rem;
            bottom: 5rem;
        }
    }
}