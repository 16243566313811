@import "../abstracts/_variables.scss";
@import "../abstracts/_mixins.scss";

.alert {
  position: fixed;
  top: 140px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 12;
  padding: 1rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  text-align: center;
  @include breakpoint($md) {
    width: 90%;
  }
}
.alert-error {
  color: #842029;
  background-color: #f8d7da;
  border-color: #f5c2c7;
}
.alert-success {
  color: #0f5132;
  background-color: #d1e7dd;
  border-color: #badbcc;
}
.forgot-password {
  &__wrapper {
    background-color: #ededed;
    padding: 4rem 0;
    min-height: 60vh;
    display: flex;
    align-items: center;
    @include breakpoint($md) {
      min-height: 50vh;
    }
  }
  &__container {
    background-color: #fff;
    padding: 4rem 3rem;
    max-width: 60% !important;
    text-align: center;
    border-bottom: 5px solid #000;
    @include breakpoint($md) {
      padding: 3rem 1rem;
      max-width: 90% !important;
    }
    h2 {
      margin-bottom: 10px;
      @include breakpoint($md) {
        font-size: 1.1rem;
      }
    }
    .error-feedback {
      color: $red;
      font-size: 0.9rem;
      font-weight: bolder;
      margin: 0.5rem 0;
    }
    .is-invalid {
      border: 1px solid $red;
    }
    input {
      background-clip: padding-box;
      background-color: #fff;
      border: 1px solid #ced4da;
      color: #495057;
      display: block;
      font-family: $secondary-font;
      font-size: 1rem;
      font-weight: 400;
      height: 50px;
      line-height: 1.5;
      padding: 0.375rem 0.75rem;
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      width: 70%;
      margin: 1rem auto 0 auto;
      @include breakpoint($md) {
        width: 100%;
      }
    }
    .re-captcha {
      display: flex;
      justify-content: center;
    }
    button {
      width: 100%;
      color: #fff;
      font-family: $main-font;
      font-size: 1.1rem;
      font-weight: bolder;
      background-color: $red;
      padding: 1rem 0;
      width: 70%;
      @include breakpoint($md) {
        margin-top: 1rem;
        width: 100%;
      }
      &:hover {
        cursor: pointer;
        background-color: darken($red, 5%);
      }
      &:disabled {
        background-color: #495057 !important;
        cursor: not-allowed;
      }
    }
  }
}
