@import "../../assets/scss/abstracts/variables";
@import "../../assets/scss/abstracts/mixins";

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  margin: 1rem auto 1rem auto;
  .page-item {
    &.disabled {
      span {
        background-color: #808080 !important;
      }
      &:hover {
        cursor: not-allowed;
      }
    }
  }
  .page-item:first-child .page-link {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
    background-color: #000;
    border: 0;
    color: #fff;
    font-size: 1.1rem;
    font-weight: bolder;
    font-family: $secondary-font;
    @include breakpoint($xl) {
      &:hover {
        z-index: 2;
        background-color: $red;
        border: 0;
      }
    }
  }
  .page-item:last-child .page-link {
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    background-color: #000;
    border: 0;
    color: #fff;
    font-size: 1.1rem;
    font-weight: bolder;
    font-family: $secondary-font;
    &:disabled {
      background-color: #f7f7f7;
    }
    @include breakpoint($xl) {
      &:hover {
        z-index: 2;
        background-color: $red;
        border: 0;
      }
    }
  }
  .page-item:not(:first-child) .page-link {
    margin-left: -1px;
  }
  .page-item.active {
    a {
      background-color: #d4d4d4;
      border: 1px solid #d4d4d4;
      color: #000;
    }
  }
  .page-link {
    // border-top-left-radius: 0.25rem;
    // border-bottom-left-radius: 0.25rem;
    padding: 0.75rem 0.75rem;
    position: relative;
    display: block;
    color: #797979;
    text-decoration: none;
    background-color: #fff;
    border: 1px solid #dee2e6;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    min-width: 35.09px;
    min-height: 45px;
    &[role="button"] {
      cursor: pointer;
    }
    &:hover {
      z-index: 2;
      color: #0a58ca;
      background-color: #e9ecef;
      // border-color: #dee2e6;
    }
  }
  .visually-hidden {
    position: absolute !important;
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    margin: -1px !important;
    overflow: hidden !important;
    clip: rect(0, 0, 0, 0) !important;
    white-space: nowrap !important;
    border: 0 !important;
  }
}
