@import "../abstracts/mixins";
@import "../abstracts/variables";

button.confirm-payment {
  background-color: $red;
  display: block;
  width: 100%;
  font-family: $main-font;
  font-size: 1rem;
  font-weight: bolder;
  color: #fff;
  padding: 1.3rem 0;
  margin-top: 2rem;
  &:hover {
    background-color: darken($red, 5%);
    cursor: pointer;
  }
}

.payment {
  background-color: #f3f3f3;
  padding: 1rem 2rem;
  min-height: 500px;
  display: flex;
  // align-items: center;
  @include breakpoint($md) {
    padding: 1rem;
  }
  .container-fluid {
    background-color: #fff;
    border-top: 2px solid $red;
    height: 100%;
    padding: 3rem 2.5rem 4rem 2.5rem;
    @include breakpoint($md) {
      padding: 3rem 1rem;
    }
    // .row {
    //   margin-top: 2rem;
    // }
    h3 {
      border-bottom: 1px solid #f3f3f3;
      text-transform: uppercase;
      letter-spacing: 1px;
      margin-bottom: 1.5rem;
      padding-bottom: 1rem;
    }
    .client-data {
      padding-right: 2rem;
      @include breakpoint($md) {
        padding-right: 0.8rem;
        order: 2;
      }
      h5 {
        display: flex;
        align-items: center;
        font-size: 1rem;
        span {
          background-color: #000;
          color: #fff;
          padding: 0.3rem 0.55rem;
          border-radius: 100%;
          margin-right: 0.5rem;
          font-size: 0.9rem;
        }
        img {
          height: 25px;
          margin-left: 0.7rem;
          @include breakpoint($md) {
            height: 20px;
          }
        }
      }
      form {
        margin: 1rem 0;
        button {
          width: 100%;
          color: #fff;
          font-family: $main-font;
          font-size: 1.1rem;
          font-weight: bolder;
          margin-top: 1rem;
          &:disabled {
            background-color: #495057 !important;
            // color: -internal-light-dark(
            //   rgba(16, 16, 16, 0.3),
            //   rgba(255, 255, 255, 0.3)
            // ) !important;
            // border-color: -internal-light-dark(
            //   rgba(118, 118, 118, 0.3),
            //   rgba(195, 195, 195, 0.3)
            // ) !important;
            cursor: not-allowed;
          }
        }
      }
      .form-group {
        display: flex;
        flex-direction: column;
        .error-feedback {
          color: $red;
          font-size: 0.9rem;
          font-weight: bolder;
          margin-top: 0.5rem;
        }
        .form-control {
          background-clip: padding-box;
          background-color: #fff;
          border: 1px solid #ced4da;
          color: #495057;
          display: block;
          font-family: $secondary-font;
          font-size: 1rem;
          font-weight: 400;
          height: 50px;
          line-height: 1.5;
          padding: 0.5rem 0.75rem;
          transition: border-color 0.15s ease-in-out,
            box-shadow 0.15s ease-in-out;
          width: 100%;
        }
        label {
          font-family: $secondary-font;
          font-weight: bolder;
          font-size: 0.9rem;
          letter-spacing: 1px;
          margin-bottom: 0.5rem;
          text-transform: uppercase;
        }
      }
      &__payment {
        .StripeElement {
          padding: 1rem;
          border: 1px solid #ced4da;
          margin-top: 1rem;
        }
        &__cards {
          img {
            height: 27px;
            @include breakpoint($md) {
              height: 30px;
            }
          }
        }
      }
    }
    .other-data__payment {
      p {
        display: inline-block;
        &:first-of-type {
          margin-top: 1rem;
        }
      }
      i {
        display: inline-block;
      }
      a {
        font-weight: bolder;
        color: rgb(19, 175, 71);
      }
      span {
        color: #495057;
        font-size: 0.9rem;
        display: block;
        margin-top: 5px;
      }
    }
    .order-summary {
      padding: 0 2rem;
      border-left: 1px solid #f3f3f3;
      @include breakpoint($md) {
        border-left: 0;
        padding: 1rem;
      }
      & > div {
        position: sticky;
        position: -webkit-sticky;
        top: 140px;
        left: 0 !important;
        right: unset;
      }
      .labels {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 1rem;
        h4 {
          font-size: 0.8rem;
          text-transform: uppercase;
          color: #495057;
          letter-spacing: 1px;
        }
      }
      .total {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1rem 0;
        h2 {
          font-family: $secondary-font;
          margin-bottom: 0;
        }
        h3 {
          padding-bottom: 0;
          margin-bottom: 0;
        }
      }
      &__item {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 1rem 0;
        border-bottom: 1.5px dashed rgba(0, 0, 0, 0.2);
        &__details {
          width: 65%;
          img {
            width: 70%;
          }
          @include breakpoint($md) {
            width: 60%;
            img {
              width: 80%;
            }
          }
        }
      }
    }
  }
}
