.cta-button {
    padding: 1.25rem 2.5rem;
	font-size: clamp(1rem, 1.2vw, 1.3rem);
    font-family: $secondary-font;
    font-weight: 700;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	@include baseTransition(.3s);
	text-decoration: none;

	&-container {
		text-align: center;

		&.align-left {
			text-align: left;
			padding-left: 0;
			padding-right: 0;
		}

		&.align-right {
			text-align: right;
			padding-left: 0;
			padding-right: 0;
		}
	}

	&__text {
		margin: 0;
		margin-right: 10px;
		@include baseTransition(.3s);

		&__icon {
			font-size: 1.5rem;
		}
	}

	&--red {
		background-color: $red;
		border-right: 10px solid #000;

		&:hover {
			background-color: #A3040A;
		}
	}
	
	&--small {
		padding: .75rem 1.5rem;
		font-size: clamp(.95rem, 1vw, 1.2rem);
	}

	&:hover {
		.cta-button__text {
			margin-right: 15px;
		}
	}

	@include breakpoint($md) {
		padding: 1rem 2rem;

		&-container { 
			padding: 1rem 0;
		}

		&--small {
			padding: .75rem 1rem;
		}
	}
}
