@import "../../assets/scss/abstracts/mixins";
@import "../../assets/scss/abstracts/variables";

.bid-product-card {
  background-color: #fff;
  .raffle-product-card {
    &__media {
      &__cta {
        background-color: $red;
        border-radius: 10px;
        color: #fff;
        display: block;
        font-family: $main-font;
        font-size: 0.9rem;
        font-weight: bolder;
        margin: 10px auto 0 auto;
        padding: 0.7rem 0.5rem;
        position: relative;
        text-align: center;
        transition: all 0.2s ease-in;
        width: 60%;
        z-index: 9;
        @include breakpoint($md) {
          padding: 0.5rem;
          width: max-content;
        }
        &:hover {
          width: 100%;
        }
      }
      .carousel-root {
        margin-top: -0.5rem;
      }
    }
  }
}
