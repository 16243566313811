@import "../../assets/scss/abstracts/mixins";
@import "../../assets/scss/abstracts/variables";

.searchInputs {
  display: flex;
  border: 1px solid #7a7a7a;
  width: 100%;
  margin-top: 1rem;
  @include breakpoint($xl) {
    margin-top: 0;
    margin-left: 3rem;
    width: 350px;
  }
}
.search {
  position: relative;
}
.search input {
  background-color: white;
  border: 0;
  border-radius: 2px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  font-size: 1rem;
  padding: 17px 15px;
  height: auto;
  width: 300px;
  font-family: $main-font;
  @include breakpoint($xl) {
    padding: 13px;
    font-size: 0.9rem;
  }
}

.searchIcon {
  // height: 60px;
  width: 50px;
  background-color: white;
  display: grid;
  place-items: center;
}

input:focus {
  outline: none;
}
.searchIcon svg {
  font-size: 35px;
}

.dataResult {
  margin-top: 5px;
  width: 100%;
  max-height: 280px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  overflow: hidden;
  overflow-y: auto;
  position: absolute;
  z-index: 10;
  // padding: 0.7rem 0;
  @include breakpoint($xl) {
    left: 3rem;
    max-height: 200px;
    width: 350px;
  }
}

.dataResult::-webkit-scrollbar {
  display: none;
}

.dataResult .dataItem {
  width: 100%;
  // height: 50px;
  display: flex;
  align-items: center;
  color: black;
}

.dataItem {
  padding: 0.5rem 0.7rem;
  &:hover {
    background-color: #cac8c8;
  }
  p {
    margin-left: 10px;
    font-weight: bolder;
    text-transform: uppercase;
  }
  img {
    height: 50px;
  }

  &:not(:first-child) {
    border-top: 1px solid #cac8c8;
  }
  &__info {
    display: flex;
    flex-direction: column;
    p {
      font-size: 1rem;
      &:nth-child(2n) {
        margin-top: 5px;
        color: $blue;
      }
    }
  }
}
a {
  text-decoration: none;
}

// a:hover {
//   background-color: lightgrey;
// }

#clearBtn {
  cursor: pointer;
}
