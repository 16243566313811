@import "../abstracts/mixins";
@import "../abstracts/variables";

.sign-in__container {
  background-color: #fff;
  padding: 4rem 3rem !important;
  max-width: 60% !important;
  @include breakpoint($md) {
    padding: 4rem 1rem !important;
    max-width: unset !important;
    width: 95%;
    h2 {
      text-align: center;
    }
    p {
      text-align: center;
      margin: 1rem 0;
    }
  }

  form {
    margin: 1rem 0;
    @include breakpoint($md) {
      margin: 1.5rem 0;
    }
    button {
      width: 100%;
      color: #fff;
      font-family: $main-font;
      font-size: 1.1rem;
      font-weight: bolder;
      margin-top: 1rem;
      @include breakpoint($md) {
        margin-top: 0.5rem;
      }
      &:hover {
        cursor: pointer;
      }
      &:disabled {
        background-color: #495057 !important;
        cursor: not-allowed;
      }
    }
  }
  .form-group {
    display: flex;
    flex-direction: column;
    .error-feedback {
      color: $red;
      font-size: 0.9rem;
      font-weight: bolder;
      margin-top: 0.5rem;
    }
  }
  input {
    background-clip: padding-box;
    background-color: #fff;
    border: 1px solid #ced4da;
    color: #495057;
    display: block;
    font-family: $secondary-font;
    font-size: 1rem;
    font-weight: 400;
    height: 50px;
    line-height: 1.5;
    margin-top: 0.5rem;
    padding: 0.375rem 0.75rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    width: 100%;
  }
  label {
    font-family: $secondary-font;
    font-weight: bolder;
    font-size: 0.9rem;
    letter-spacing: 1px;
    // margin-bottom: 0.5rem;
    text-transform: uppercase;
  }
}
.sign-in {
  background-color: #ededed;
  padding: 4rem 0;
  min-height: 60vh;
  display: flex;
  align-items: center;
  @include breakpoint($md) {
    padding: 3rem 0;
  }
  a {
    &.register {
      color: #000;
      font-weight: bolder;
      text-align: center;
      font-size: 0.9rem;
      padding: 0.5rem 1rem;
      &:hover {
        background-color: #000;
        color: #fff;
      }
    }
    &.forgot-password {
      padding: 0;
      color: rgb(14, 29, 188);
      font-weight: bolder;
      // text-align: center;
      padding-left: 10px;
      font-size: 0.8rem;
      @include breakpoint($md) {
        font-size: 0.9rem;
      }
    }
  }
  .error-container {
    background-color: rgb(241, 241, 241);
    border-left: 4px solid $red;
    padding: 1rem 0;
    margin: 1rem 0;
    p {
      padding-left: 1.5rem;
      span {
        font-weight: bolder;
      }
    }
  }
}
