@import "../../assets/scss/abstracts/mixins";
@import "../../assets/scss/abstracts/variables";

.order-summary {
  padding: 0 2rem;
  border-left: 1px solid #f3f3f3;
  .labels {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1rem;
    h4 {
      font-size: 0.8rem;
      text-transform: uppercase;
      color: #495057;
      letter-spacing: 1px;
    }
  }
  &__total {
    // display: flex;
    // justify-content: space-between;
    // align-items: center;
    padding: 1rem 0;
    h2 {
      font-family: $secondary-font;
      margin-bottom: 0;
    }
    h3 {
      padding-bottom: 0;
      margin-bottom: 0;
    }
  }
  &__item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 0;
    border-bottom: 1.5px dashed rgba(0, 0, 0, 0.2);
    img {
      width: 50%;
    }
    &__details {
      h5 {
        font-size: 1rem;
        margin-bottom: 0.4rem;
      }
      p {
        font-size: 0.9rem;
      }
    }
  }
}
