@import "../abstracts/mixins";
@import "../abstracts/variables";

.order {
  &__wrapper {
    background-color: #ededed;
    min-height: 60vh;
    display: flex;
    align-items: center;
    padding: 2rem;
    @include breakpoint($md) {
      padding: 1rem 0;
    }
  }
  &__container {
    background-color: #fff;
    padding: 2rem;
    width: 90%;
    border-bottom: 2px solid $red;
    margin: 0 auto;
    @include breakpoint($md) {
      padding: 1rem;
    }
    h2 {
      margin: 1rem 0;
    }
    p {
      border-bottom: 1px solid #ededed;
      padding: 0.5rem 0;
      span {
        &:first-child {
          font-weight: bolder;
        }
      }
    }
    .items {
      padding: 1rem;
      h4 {
        margin-bottom: 0.5rem;
      }
    }
  }
}

.orders {
  &__wrapper {
    background-color: #ededed;
    min-height: 60vh;
    display: flex;
    align-items: center;
    padding: 2rem;
    @include breakpoint($md) {
      padding: 1rem 0;
    }
  }
  &__container {
    background-color: #fff;
    padding: 2rem;
    width: 90%;
    border-top: 2px solid $red;
    margin: 0 auto;
    @include breakpoint($md) {
      padding: 2rem 1rem;
    }
    &__heading {
      border-bottom: 2px solid #ededed;
      padding-bottom: 1rem;
      h2 {
        margin-bottom: 0.5rem;
      }
    }
    &__list {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
      grid-gap: 2rem;
      margin-top: 1rem;
      @include breakpoint($md) {
        grid-template-columns: repeat(auto-fit, minmax(270px, 1fr));
      }
      .order-card {
        border: 1px solid #ededed;
        padding: 1rem;
        &:hover {
          box-shadow: 0 4px 8px 3px rgba(205, 205, 205, 0.4);
        }
        p {
          color: #000;
          margin-bottom: 0.5rem;
          span {
            &:first-child {
              font-weight: bolder;
            }
          }
        }
      }
    }
  }
}
