@import "../../assets/scss/abstracts/mixins";
@import "../../assets/scss/abstracts/variables";

.alert {
  position: fixed;
  top: 2rem !important;
  left: 50%;
  transform: translateX(-50%);
  z-index: 9;
  text-align: center;
  width: 90%;
  @include breakpoint($md) {
    width: 60%;
  }
  &-danger {
    color: #842029;
    background-color: #f8d7da;
    border-color: #f5c2c7;
  }
}
