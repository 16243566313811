.footer {
  &__main {
    padding: 5vh 70px;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
    background-color: #fbfbfb;
    &__section {
      &--links {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
      }
    }

    &__link,
    &__socialLink {
      &__header {
        margin-bottom: 10px;
        text-transform: uppercase;
        font-family: $main-font;
        font-weight: 600;
      }
    }

    &__link,
    &__link__header,
    &__socialLink__header {
      display: block;

      &--mobile {
        display: none;
      }
    }

    &__link {
      // font-size: 0.85rem;
      color: #000;
      margin-bottom: 2.5px;

      &-container {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;

        &--mobile {
          display: none;
        }

        &--brands {
          .footer__main__link {
            text-transform: uppercase;
          }
        }
      }

      &__text {
        @include baseTransition(0.3s);
        display: inline-block;
        padding: 2.5px 0;
        border-bottom: 1.5px solid transparent;
      }

      &:hover {
        .footer__main__link__text {
          border-bottom: 1.5px solid #000;
        }
      }
    }

    &__socialLink {
      margin-right: 1rem;
      color: $red;
      font-size: 2rem;
      img {
        height: 35px;
        vertical-align: middle;
        @include breakpoint($md) {
          height: 33px;
          vertical-align: sub;
        }
      }
      &-container {
        display: flex;
        align-items: center;
      }
    }
  }

  &__logo {
    width: 130px;
    display: block;

    &-container {
      display: block;
      padding-bottom: 2rem;
    }
  }

  &__text,
  &__copyright,
  &__credits {
    font-weight: 500;
    font-size: clamp(0.65rem, 0.925vw, 0.85rem);
    line-height: 1.15rem;
  }

  &__bottom {
    display: flex;
    flex-wrap: wrap;
    padding: 1rem 70px;
  }

  &__copyright,
  &__credits {
    display: flex;
    align-items: center;
  }

  &__credits {
    justify-content: flex-end;
  }

  @include breakpoint($md) {
    &__main {
      padding: 3rem 1rem;

      &__link,
      &__link-container,
      &__link__header {
        &--desktop {
          display: none;
        }

        &--mobile {
          display: block;
        }
      }

      &__link {
        &-container {
          padding-right: 0 !important;
          padding-left: 0 !important;
          margin-bottom: 1rem;
        }

        &__header {
          &--bottom {
            margin-top: 10px;
            margin-bottom: 0;
          }
        }
      }

      &__section--social {
        display: none;
      }
    }

    &__text {
      display: none;
    }

    &__bottom {
      padding: 1rem;
    }
    &__logo {
      width: 200px;
    }
    &__copyright {
      font-size: 12px;
    }
    &__credits {
      justify-content: flex-start;
      border-top: 1px solid #e9e9e9;
      margin-top: 5px;
    }
  }
}
