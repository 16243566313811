.cart {
  &__container {
    padding: 2.5rem 70px;
    overflow: hidden;

    &::before {
      content: "";
      display: block;
      position: absolute;
      z-index: -1;
      top: 0;
      left: 0;
      width: 300px;
      height: 450px;
      background-color: #f3f3f3;
    }
  }

  &__header {
    padding: 1.5rem 0;
  }

  &__title {
    font-size: 1.5rem;
    font-weight: 500;
    font-family: $main-font;
  }

  &__products {
    padding: 0 1rem;

    &__header {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      background-color: #000;
      padding: 2rem;

      &__title {
        color: #fff;
        padding: 0 1.5rem;
        font-weight: 600;
        font-size: 1.3rem;
        font-family: $main-font;

        &-container {
          &:not(:first-child) {
            text-align: center;
          }

          &--product {
            width: 55%;
          }

          &--price,
          &--subtotal {
            width: 15%;
          }

          &--quantity,
          &--actions {
            width: 12.5%;
          }
        }
      }
    }
  }

  &__item {
    background-color: #fff;
    padding: 0 15px;

    &-container {
      padding-bottom: 1rem;
      background-color: #fff;
      border: 1.5px solid rgba(0, 0, 0, 0.1);
      p {
        // text-align: center;
        margin: 1rem 0;
      }
    }

    &-holder {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      // flex-wrap: wrap;
      border-bottom: 1.5px solid rgba(0, 0, 0, 0.1);
      padding: 1rem 0;

      & > div {
        padding: 0 15px;

        &:not(:nth-child(2)) {
          text-align: center;
        }
      }
    }

    &__name,
    &__price,
    &__subtotal {
      font-weight: 600;
      text-transform: uppercase;
      font-size: clamp(1.05rem, 1.15vw, 1.4rem);
      line-height: clamp(1.5rem, 1.65vw, 2rem);
    }

    &__image,
    &__price,
    &__subtotal {
      &-container {
        width: 15%;
      }
    }

    &__image {
      width: 90%;
      margin: 0 auto;
      display: block;
    }

    &__name {
      &-container {
        width: 40%;
      }
    }

    &__subtotal {
      &__header {
        display: none;
      }
    }

    &__quantity,
    &__actions {
      &-container {
        width: 12.5%;
      }
    }

    &__quantity {
      &-holder {
        display: flex;
        align-items: center;
        justify-content: center;
        // margin-left: 1rem;
        h5 {
          font-size: 1rem;
        }
        button.counter-action {
          font-size: 1.1rem;
          height: 33px;
          width: 33px;
          cursor: pointer;
          background-color: #dcdcdc;
          &:hover {
            background-color: #dcdcdc;
          }
          &:disabled {
            background-color: rgba(239, 239, 239, 0.4);
            &:hover {
              cursor: not-allowed;
              background-color: rgba(239, 239, 239, 0.3);
            }
          }
        }
      }
      &-container {
        display: flex;
        align-items: center;
        // margin-bottom: 1rem;
        p {
          text-transform: uppercase;
          font-weight: bolder;
          color: #878787;
        }
      }
      &__count-button,
      &__value {
        padding: 10px;
        width: 35px;
        height: 35px;
      }

      &__count-button {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        border: 1.5px solid rgba(0, 0, 0, 0.1);
        @include baseTransition(0.2s);

        &__icon {
          font-size: 1.2rem;
        }

        &:hover {
          background-color: rgba(0, 0, 0, 0.1);
        }
      }
      &__value {
        color: #000 !important;
      }
    }

    &__action {
      display: inline-block;
      cursor: pointer;
      padding: 10px;
      border-radius: 10px;
      @include baseTransition(0.15s);

      &__icon {
        font-size: 1.2rem;
        @include baseTransition(0.15s);
      }

      &:hover {
        background-color: $red;

        .cart__item__action__icon {
          color: #fff;
        }
      }
    }

    &__total {
      &-container {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 1rem;
      }

      &__header,
      &__value {
        font-weight: 600;
        font-size: clamp(1.2rem, 1.2vw, 1.4rem);
      }

      &__header {
        margin-right: 10px;
        color: #878787;
      }
    }
  }

  &__action {
    padding: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20%;
    @include baseTransition(0.3s);
    border: 4px solid transparent;

    &-container {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      flex-wrap: wrap;
      grid-gap: 1rem;
      padding: 1.5rem 0;
    }

    &__text {
      color: #fff;
      font-weight: 600;
      font-size: clamp(1.2rem, 1.2vw, 1.5rem);
    }

    &--shop {
      background-color: #000;
      width: max-content;
      &:hover {
        background-color: transparent;
        border-color: #000;

        .cart__action__text {
          color: #000;
        }
      }
    }

    &--checkout {
      background-color: $red;
      width: max-content;
      &:hover {
        background-color: transparent;
        border-color: $red;

        .cart__action__text {
          color: $red;
        }
      }
    }
  }

  @include breakpoint($md) {
    &__container {
      padding: 2.5vh 1rem;

      &::before {
        width: 45%;
        height: 250px;
      }
    }

    &__products {
      padding: 0;

      &__header {
        padding: 2rem 15px;

        &__title {
          padding: 0;

          &-container {
            width: 100%;

            &:not(:first-child) {
              display: none;
            }
          }
        }
      }
    }

    &__item {
      &-holder {
        position: relative;
        justify-content: flex-start;
        padding: 1rem;
        & > div {
          padding: 0;
        }
      }

      &__image {
        // width: 100%;
        display: none;
        &-container {
          width: 0;
        }
      }
      &-container {
        padding-bottom: 0;
      }
      &__name,
      &__subtotal,
      &__quantity {
        &-container {
          width: 100%;
          margin-bottom: 0;
        }
      }
      &__name {
        &-container {
          width: 50%;
        }
      }
      &__price-container {
        // display: none;
        width: 100%;
      }

      &__quantity {
        &-container {
          order: 3;
          width: 70%;
        }
        &-holder {
          justify-content: flex-start;
        }
      }

      &__subtotal {
        &-container {
          text-align: left !important;
          display: flex;
          align-items: center;
        }

        &__header {
          display: block;
          margin-right: 10px;
          font-weight: 600;
          color: #878787;
        }
      }

      &__actions-container {
        position: absolute;
        top: 1rem;
        right: 5px;
      }
      &__action {
        order: 4;
      }
    }

    &__action {
      width: 100%;
      &--checkout,
      &--shop {
        width: 100%;
      }
      &-container {
        padding: 1.5rem 0;
        grid-gap: 0.5rem;
      }
    }
  }
}
