@import "../abstracts/_variables.scss";
@import "../abstracts/_mixins.scss";

// .navbar__link--cart {
//   display: none;
// }

.confirmation {
  min-height: 60vh;
  position: relative;
  display: flex;
  padding: 3rem 0;
  align-items: center;
  justify-content: center;
  @include breakpoint($md) {
    padding: 3rem 1rem;
    min-height: unset;
    height: 70vh;
  }
  &::before {
    content: "";
    background-color: #f3f3f3;
    position: absolute;
    left: 0;
    top: 0;
    width: 622px;
    height: 350px;
    z-index: -1;
  }
  &__container {
    background-color: #fff;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.06);
    padding: 0;
    @include breakpoint($md) {
      height: 470px;
    }
    @include breakpoint($xl) {
      margin: 0 auto;
      width: 60%;
    }
    h3 {
      background-color: #000;
      padding: 1rem 0;
      color: #fff;
    }
    &__content {
      @include breakpoint($md) {
        height: 400px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0 1rem;
        img {
          height: 35px;
        }
      }
      @include breakpoint($xl) {
        margin: 2rem 0;
      }
      h4 {
        font-size: 1.4rem;
        color: #565656;
        letter-spacing: 0.75px;
        line-height: 25px;
        margin: 1rem 0;
        span {
          color: #000;
        }
      }
      p {
        margin: 0 auto 1.5rem auto;
        @include breakpoint($xl) {
          width: 70%;
        }
      }
    }
    &__cta {
      margin-top: 2rem;
      @include breakpoint($md) {
        margin-top: 0;
      }
      a,
      p {
        background-color: #000;
        margin-right: 1rem;
        color: #fff;
        font-family: $main-font;
        font-weight: bolder;
        padding: 1.1rem;
        display: inline-block;
        transition: all 0.3s ease-in-out;
        @include breakpoint($xl) {
          width: 297px;
        }
        &:hover {
          background-color: #565656;
          cursor: pointer;
        }
        &:nth-child(2n) {
          background-color: $red;
          &:hover {
            background-color: darken($red, 5%);
          }
        }
      }
    }
  }
}
