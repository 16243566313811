header {
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #fff;
  z-index: 10;

  .navbar {
    &__logo,
    &__link {
      &-container {
        display: flex;
        justify-content: center;
        align-items: baseline;
      }
    }

    &__logo {
      &-container {
        padding: 1rem;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #f0f0f0;
        align-items: center;
        @include breakpoint($md) {
          flex-direction: column;
        }
        @include breakpoint($xl) {
          padding: 1rem 1.5rem;
          & > div {
            width: 33.33%;
          }
        }
        &__social {
          display: flex;
          align-items: center;
          a {
            i {
              font-size: 23px;
              color: $red;
            }
            img {
              height: 24.5px;
            }
          }
          @include breakpoint($md) {
            // display: none;
            padding-bottom: 10px;
            justify-content: space-between;
            width: 100%;
          }
        }
        &__cart {
          @include breakpoint($md) {
            padding-right: 10px;
          }
        }
      }
    }
    p {
      font-size: 0.9rem;
      &:hover {
        cursor: pointer;
      }
    }

    button {
      background-color: #fff !important;
      &:hover {
        cursor: pointer;
      }
    }
    &__link {
      padding: 0 2.5vw;
      &-container {
        padding: 0.6rem 0 0.5rem 0;
      }
      &-holder {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0.35rem 0;
        border-bottom: 2.5px solid transparent;
        color: #000;
        @include baseTransition(0.3s);
        position: relative;
        p.cart-qty {
          position: absolute;
          top: -5px;
          left: 1rem;
          background-color: $red;
          border-radius: 50%;
          font-size: 0.8rem;
          color: #fff;
          font-weight: bolder;
          // padding: 0.1rem 0.4rem;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 20px;
          width: 20px;
        }
      }
      &--profile {
        padding: 0 1rem !important;
        img {
          height: 1.4rem;
          // margin-left: 2rem;
        }
      }
      &__text {
        font-size: 0.85rem;
        font-family: $main-font;
        font-weight: 500;
        text-transform: uppercase;
        color: #000;
      }

      &__icon {
        height: 1.2rem;
      }

      &.active,
      &:hover {
        .navbar__link-holder {
          border-color: $red;
        }
      }
    }

    &__toggle {
      display: none;
      position: fixed;
      bottom: 4.5rem;
      right: 1.5rem;
      z-index: 11;

      &__button {
        background-color: #d8d8d8;
        height: 65px;
        width: 65px;
        border-radius: 50%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        @include baseTransition(0.3s);

        &__bar {
          width: 27.5px;
          height: 3.25px;
          background-color: #000;

          // &:first-child {
          //   margin-bottom: 5px;
          // }
        }

        &:hover {
          background-color: #aaa;
        }
      }
    }
  }

  @include breakpoint($md) {
    .navbar {
      &__container {
        position: relative;
      }

      &__logo {
        width: 184px;
        z-index: 11;

        &-container {
          z-index: 11;
        }

        &-link {
          z-index: 11;
        }
      }

      &__link {
        &--cart {
          position: fixed;
          right: 15px;
          top: 30px;
          z-index: 11;
        }

        &__icon {
          height: 20px;
        }

        &-container {
          position: absolute;
          top: -100vh;
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;
          background-color: #f9f9f9;
          height: 100vh;
          width: 100vw;
          @include baseTransition(0.3s);
          z-index: 10;

          &.open {
            top: 80px;
            @include breakpoint($md) {
              top: 0;
            }
          }
        }

        &-holder {
          border: none;
        }

        &--dropdown {
          .navbar__link-holder::after {
            content: "\f178";
            font-family: "Font Awesome 5 Pro";
            font-size: 1.5rem;
          }
        }

        &:not(.navbar__link--cart) {
          width: 100%;
          padding: 1.5rem;
          border-bottom: 1px solid rgba(0, 0, 0, 0.1);
          @include baseTransition(0.3s);

          &:hover {
            background-color: #cecece;
          }
        }
      }

      &__toggle {
        display: block;
      }
    }
  }
}
