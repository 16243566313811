@import "../abstracts/_variables.scss";
@import "../abstracts/_mixins.scss";

.raffles {
  &__header {
    background-color: #f7f6f6;
    padding: 3rem 0 2rem 0;
    @include breakpoint($md) {
      padding: 2rem 0;
    }
    h3 {
      font-size: 1.7rem;
      margin-bottom: 0.5rem;
    }
    p {
      text-align: justify;
    }
  }
  &__container {
    padding: 2rem 0;
    background-color: #fbfbfb;
    @include breakpoint($md) {
      background-color: #ffffff;
    }
  }
}
